import Home from "./Home";
import About from "./About";
import Profilelink from "../Components/Profilelink";

const Main = () => {
  return (
    <>
    <Home/>
    <About/>
    <Profilelink/>
    </>
  );
};

export default Main;